import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import contactWoman from "../../img/contact_woman.png";
import arrowWhite from "../../img/arrow_white.svg";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout pageId="contact" metadata={{ title: "Get Involved" }}>
        <section className="section">
          <div className="container">
            <div className="content">

              <div className="columns">

                <div className="column is-6">
                  <h1 className="h1">Let's make an impact together</h1>
                  <form
                    name="contact"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>

                      <div className="field ">
                        <label className="label h4" htmlFor={"name"}>
                          Name
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={"text"}
                            name={"name"}
                            onChange={this.handleChange}
                            id={"name"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label h4" htmlFor={"email"}>
                          Email
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={"email"}
                            name={"email"}
                            onChange={this.handleChange}
                            id={"email"}
                            required={true}
                          />
                        </div>
                      </div>

                    <div className="field">
                      <label className="label h4" htmlFor={"message"}>
                        Message
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={"message"}
                          onChange={this.handleChange}
                          id={"message"}
                          required={true}
                        />
                      </div>
                    </div>
                    <button className="button" type="submit">
                      <span>Submit</span>
                      <img src={arrowWhite} alt="Young woman with phone" className="arrowWhite"/>
                    </button>
                  </form>
                </div>
                <div className="column is-2">
                </div>
                <div className="address column is-4">
                  <img src={contactWoman} alt="Young woman with phone" />
                  <address>

                    <p className="h4">
                      <a href="tel:+12026181588">202-618-1588</a>
                    </p>
                    <p className="h4">
                      <a href="mailto:hello@impactual.com" title="Email us">
                        hello@impactual.com
                      </a>
                    </p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
